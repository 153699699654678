<template>
  <buttons exit="" main=""/>
  <div class="containerDepositConfirm">
    <div class="insertedSummaText1">{{this.sum}}₽</div>
      <div class="insertedSummaText2">ВНЕСЕНО В БАНКОМАТ</div>
  </div>

      <div class="btnDepositMoney" @click="depositMoney">ВНЕСТИ</div>
      <a class="btnAddMore" :href="'/deposit/'+this.$route.params.id+ '/' + this.sum" :style="{ display: parseInt(this.sum) === 10000000 ? 'none' : 'flex' }">ДОБАВИТЬ ЕЩЕ</a>

</template>

<script>

import Buttons from "@/components/buttons.vue";
import axios from "axios";

export default {
  data() {
    return{
      sum:"",
    }
  },
  methods:
      {
        async depositMoney(){
          console.log(this.sum)
          console.log(process.env.VUE_APP_API_URL+'/api/user/operation?id='+this.$route.params.id+'&amount='+this.sum+'&type=deposit')
          await axios.post(process.env.VUE_APP_API_URL+'/api/user/operation?id='+this.$route.params.id+'&amount='+this.sum+'&type=deposit').then(response=>{
            window.location.href = '/loading/True';
          }).catch((e)=>{
            console.log(e)
          })
        },
        getSum(){
            this.sum=this.$route.params.sum
        }
      },
  components: {Buttons},
  beforeMount() {
    this.getSum()
  }
}
</script>

<style>
.containerDepositConfirm{

  border-radius: 25px;
  border: 3px solid #00FFD4;
  margin: auto;
  width: 60%;
  display: block;
}

.insertedSummaText1{
  color: #DFDFDF;
  font-family: Manrope,serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-direction: column;
  display: flex;
  margin: 40px;

}

.insertedSummaText2{
  color: #00FFD2;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  font-family: Manrope,serif;
  flex-direction: column;
  margin: 40px;
  text-align: right;

}

.btnDepositMoney{
  border-radius: 25px;
  background: #00FFD2;
  margin: 20px auto 0;
  width: 60%;
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  color: #070707;
  text-align: center;
  font-family: Manrope,serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.btnAddMore{
  width: 60%;
  height: 100px;
  border-radius: 25px;
  display: flex;
  margin: 20px auto;
  font-size: 55px;
  font-weight: 500;
  flex-direction: column;
  justify-content: center;
  font-family: Manrope,serif;
  text-align: center;
  background: none;
  border: 3px solid #DFDFDF;
  color: #DFDFDF;
}

.btnDepositMoney:hover,.btnAddMore:hover{
  background-color: rgba(255, 255, 255);
  color: #000000;
  transition: background 0.5s ease;
}

</style>
