<template>

  <buttons exit="1" main="1"/>
  <div class="grid">
    <div></div>
    <div>
      <div class="containerDeposit">
        <div class="TitleDeposit">
          ВНЕСИТЕ ДЕНЬГИ ПАЧКОЙ ДО 200 КУПЮР
        </div>
        <div class="noteDeposit">
          ● БЕЗ СКРЕПОК, ЗАЖИМОВ И МОНЕТ
        </div>
        <input class="inputs" v-model="sum" placeholder="ВВЕДИТЕ СУММУ" maxlength="7" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" />
      </div>
      <a class="btnDeposit" @click="CheckSum">ВНЕСТИ</a>>
    </div>
    <div >
      <div v-show="flag" class="errorMessage">НЕДОПУСТИМАЯ СУММА!!!</div>
      <img class="cosmoDeposit" src="../../assets/images/kosmo.png" alt="" draggable="false">
    </div>
  </div>

</template>

<script>
import Buttons from "@/components/buttons.vue";
import axios from "axios";


export default {
  data() {
    return{
      sum:"",
      flag:""
    }
  },
  methods:
      {
        async CheckSum() {
          if (!(parseInt(this.sum) % 50 === 0) || (parseInt(this.sum) > 1000000 || parseInt(this.sum)+parseInt(this.$route.params.sum) > 10000000 || parseInt(this.sum) === 0)){
            this.sum = ""
            this.flag = "True"
          }
          if (this.sum !== ""){
            this.sum=parseInt(this.sum)
            this.sum+=parseInt(this.$route.params.sum)
            window.location.href = '/deposit/depositConfirm/'+this.$route.params.id+'/'+this.sum
          }
        },
      },
  components: {Buttons}
}
</script>

<style>

.grid{
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}


.containerDeposit{
  border-radius: 25px;
  border: 3px solid #00FFD4;
  margin: 30px auto 0;
  width: 100%;
  display: block;
}

.TitleDeposit{
  color: #00FFD2;
  font-family: Manrope,serif;
  font-size: 70px;
  font-weight: 400;
  display: flex;
  margin: 10px auto 0;
  width: 85%;
}

.noteDeposit{
  color: #DFDFDF;
  font-family: Manrope,serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  margin: 30px auto 0;
  width: 85%;
}


.inputs{
  width: 85%;
  border-radius: 25px;
  border: 3px solid #DFDFDF;
  display: flex;
  color: #DFDFDF;
  text-align: center;
  font-family: Manrope,serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 50px auto;
  background: none;
}

.btnDeposit{
  width: 100%;
  border-radius: 25px;
  background: #00FFD2;
  margin: 30px auto;
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  color: #070707;
  text-align: center;
  font-family: Manrope,serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.btnDeposit:hover{
  background-color: rgba(255, 255, 255);
  color: #000000;
  transition: background 0.5s ease;
}

.cosmoDeposit{;
  position: absolute;
  top:420px;
  right:180px;
  transform: rotate(35.834deg);
}

.errorMessage{
  width: 60%;
  height: 80px;
  border-radius: 25px;
  border: 3px solid #00FFD4;
  background: none;
  color: #00FFD4;
  text-align: center;
  font-family: Manrope,serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 100px auto;
  padding: 30px;
}

</style>
