<template>
  <buttons exit="1"/>
  <div class="helloBalance">
    <div class="helloText">
      Приветствую Вас, Землянин!
    </div>
    <div class="balance" id="balance" @click="showBalance">
      <div class="balanceItems">
          <div class="showBalance">БАЛАНС КАРТЫ</div>
          <div v-show="this.flagShow" class="balanceNum">●●●●●●●● ₽</div>
          <div v-show="this.flagShow1" class="balanceNum">{{this.balance}}₽</div>
        </div>
        <div class="numberTypeCard">
          <div class="numberCard">... {{this.numberCard}}</div>
          <div class="typeCard">{{this.typeCard}}</div>
        </div>
      </div>
    </div>
  <div class="btnPay">
    <a :href="'/takeOff/'+this.$route.params.id">
      <div class="btnPayStyle">СНЯТЬ</div>
    </a>
    <a :href="'/oplata/'+this.$route.params.id">
      <div class="btnPayStyle">ОПЛАТИТЬ</div>
    </a>
    <a :href="'/deposit/'+this.$route.params.id + '/0'">
      <div class="btnPayStyle">ВНЕСТИ</div>
    </a>
  </div>

</template>

<script>
import Buttons from "@/components/buttons.vue";
import axios from "axios";

export default {
  data() {
    return{
      numberCard:"",
      typeCard:"",
      flagShow:"1",
      flagShow1:"",
      balance:0,
      id:""
    }
  },
  methods:{
    async getData(){
      await axios.get(process.env.VUE_APP_API_URL+'/api/user/get?id='+this.$route.params.id).then(response=>{
        console.log(response.data)
        this.balance=response.data.balance
        this.typeCard=response.data.payment_system
        this.numberCard=response.data.card_number
      }).catch((e)=>{
        console.log(e)
      })
    },
    async showBalance(){
      if (this.flagShow==="1"){
        this.flagShow=""
        this.flagShow1="1"
      }
      else{
        this.flagShow="1"
        this.flagShow1=""
      }
    }
  },
  beforeMount() {
    this.getData()
  },
  components: {Buttons}
}
</script>

<style>
.helloBalance{
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 300px;
  margin: 125px auto 0;
}

.helloText{
  color: #DFDFDF;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Manrope,serif;
  font-size: 85px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.balance{
  width: 70%;
  border-radius: 25px;
  border: 3px solid #00FFD4;
  cursor: pointer;
}
.showBalance{
  display: flex;
  color: #00FFD2;
  font-family: Manrope,serif;
  font-size: 60px;
  font-weight: 400;
  margin: 40px 30px 80px ;
}

.showBalance{
  margin: 18px 30px 0 ;
}

.numberTypeCard{
  color: #00FFD4;
  font-family: Manrope,serif;
  font-size: 50px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.typeCard{
  margin: 0 30px;
}

.balanceNum{
  color: #00FFD4;
  font-family: Manrope,serif;
  font-size: 60px;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  letter-spacing:5px
}

.btnPay{
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 80px auto;
}

.btnPayStyle{
  width: 500px;
  height: 150px;
  border-radius: 25px;
  background: #00FFD2;
  color: #040404;
  font-family: Manrope,serif;
  font-size: 40px;
  font-weight: 500;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.btnPayStyle:hover{
    background-color: rgba(255, 255, 255);
    color: #000000;
    transition: background 0.5s ease;
}
</style>