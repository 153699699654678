<template>
  <div class="grid">
    <div>
      <div v-if="!this.flag" class="noMoney">НЕДОСТАТОЧНО СРЕДСТВ!!!</div>
      <div v-else class="okMoney">✔</div>
      <img class="kosmoStatus" src="../../assets/images/kosmo.png" alt="" draggable="false">
    </div>
    <div>
      <div class="info">
        <div v-if="this.flag" class="infoText">ОПЕРАЦИЯ ВЫПОЛНЕНА</div>
        <div v-else class="infoText">НЕ УДАЛОСЬ ВЫПОЛНИТЬ ОПЕРАЦИЮ</div>
      </div>
      <a class="btnInfoExit" href="/"><div>ВЫХОД</div></a>
      <a class="btnInfoMain" href="/pin">ГЛАВНЫЙ ЭКРАН</a>
      </div>
    <div></div>
  </div>
</template>

<script>
export default {
  data() {
    return{
      flag:""
    }
  },
  methods:
      {
        flagStatus(){
          if (this.$route.params.flag==="True"){
            this.flag=1
          }
          else{
            this.flag=0
          }
        }
      },
  beforeMount() {
    this.flagStatus()
  }
}
</script>

<style>
.grid{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.info{
  border-radius: 25px;
  border: 3px solid #00FFD4;
  width: 100%;
  display: flex;
  margin: 170px auto 20px;
  height: 300px;
}
.infoText{
  color: #00FFD2;
  font-family: Manrope,serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-direction: column;
  display: flex;
  margin: 40px;

}

.noMoney, .okMoney{

  border-radius: 25px;
  border: 3px solid #00FFD4;
  background: none;
  color: #00FFD4;
  text-align: center;
  font-family: Manrope,serif;

  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 30px;
}

.noMoney{
  margin: 250px 50px;
  font-size: 30px;
  width: 60%;
}

.okMoney{
  margin: 260px 120px;
  font-size: 50px;
  width: 30%;
}


.kosmoStatus{
  position: absolute;
  top:370px;
  left:60px
}

.btnInfoExit,.btnInfoMain{
  width: 100%;
  height: 120px;
  border-radius: 25px;
  display: flex;
  margin: 0 auto;
  font-size: 55px;
  font-weight: 500;
  flex-direction: column;
  justify-content: center;
  font-family: Manrope,serif;
  text-align: center;
}

.btnInfoExit{
  background: #00FFD2;
  color: #070707;
}

.btnInfoMain{
  background: none;
  border: 3px solid #DFDFDF;
  color: #DFDFDF;
  margin: 20px auto;
}

.btnInfoExit:hover,.btnInfoMain:hover{
  background-color: rgba(255, 255, 255);
  color: #000000;
  transition: background 0.5s ease;
}

</style>