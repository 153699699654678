<template>
  <div class="bg">
    <img class="stars" src="./assets/images/bg.png" alt="" draggable="false"/>
  </div>
  <router-view/>
</template>

<style>
a{
  text-decoration: none;
}

.bg{
  background: #000000;
}

.stars{
  min-height: 100%;
  min-width: 640px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.95;
  z-index: -1;
}
@font-face {
  font-family: Manrope;
  src: url('./assets/fonts/Manrope-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

</style>
<script>
</script>