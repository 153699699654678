<template>
  <div class="loadContainer">
    <div class="loadText">ОЖИДАЙТЕ ОПЕРАЦИЯ ВЫПОЛНЯЕТСЯ ...</div>
  </div>
  <img class="kosmoLoad" src="../../assets/images/kosmoLoad.png" alt="">
</template>

<script>
export default {
  methods:{
    go (){
        window.location.href = '/operationStatus/'+this.$route.params.flag
    }
  },
  beforeMount() {
    setTimeout(this.go, Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000);
  },
}
</script>

<style>
.loadContainer{
  border-radius: 25px;
  border: 3px solid #DFDFDF;
  width: 60%;
  display: flex;
  margin: 150px auto;
}

.loadText{
  color: #DFDFDF;
  text-align: center;
  font-family: Manrope,serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 50px 0;
}

.kosmoLoad{
  display: flex;
  margin: 0 auto;
}
</style>