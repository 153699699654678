import { createRouter, createWebHistory } from 'vue-router'
import start from '../components/pages/start.vue'
import pin from '../components/pages/pin.vue'
import main from '../components/pages/main.vue'
import oplata from '../components/pages/oplata.vue'
import mobile from "@/components/pages/mobile.vue"
import operationLoad from "@/components/pages/operationLoad.vue"
import operationStatus from "@/components/pages/operationStatus.vue"
import deposit from '../components/pages/deposit.vue'
import depositConfirm from '../components/pages/depositConfirm.vue';
import communal from "@/components/pages/communal.vue";
import receipt from "@/components/pages/receipt.vue";
import takeOff from "@/components/pages/takeOff.vue";




const routes = [
  {
    path: '/',
    name: 'start',
    component: start
  },
  {
    path: '/pin',
    name: 'pin',
    component: pin
  },
  {
    path: '/main/:id',
    name: 'main',
    component: main
  },
  {
    path: '/oplata/:id',
    name: 'oplata',
    component: oplata
  },
  {
    path: '/takeOff/:id',
    name: 'takeOff',
    component: takeOff
  },
  {
    path:'/oplata/mobile/:id',
    name: 'mobile',
    component: mobile
  },
  {
    path:'/loading/:flag',
    name: 'loading',
    component: operationLoad
  },
  {
    path:'/operationStatus/:flag',
    name: 'operationStatus',
    component: operationStatus
  },
  {
    path: '/oplata/communal/:id',
    name: 'communal',
    component: communal,
  },
  {
    path: '/oplata/communal/qr/:id',
    name: 'receipt',
    component: receipt,
  },
  {
    path:'/deposit/:id/:sum',
    name: 'deposit',
    component: deposit
  },
  {
    path:'/deposit/depositConfirm/:id/:sum',
    name: 'depositConfirm',
    component: depositConfirm
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
