<template>
  <div class="container">
    <div class="containerBtn">
      <a href="/">
        <div v-show="props.exit" class="btnExit">
          <div class="btnText">
            ВЫХОД
          </div>
          </div>
      </a>
      <a href="/pin/">
        <div v-show="props.main" class="btnMain">
          <div class="btnText">
            ГЛАВНЫЙ ЭКРАН
          </div>
        </div>
      </a>
    </div>
    <img class="logo" src="../assets/images/logo.png" alt="" draggable="false">
  </div>
</template>

<script setup>
const props = defineProps(['exit','main'])
</script>

<style>
.container{
  display: flex;
  justify-content: space-between;
}
.btnExit{
  display: flex;
  width: 260px;
  height: 100%;
  border-radius: 25px;
  background: #00FFD2;
  cursor: pointer;
  color: #040404;
  font-family: Manrope,serif;
  font-size: 30px;
  font-weight: 500;
}

.btnText{
  margin: auto;
}

.btnExit:hover,.btnMain:hover{
  background-color: rgba(255, 255, 255);
  color: #000000;
  transition: background 0.5s ease;
}

.btnMain{
  width: 310px;
  height: 100%;
  border-radius: 25px;
  border: 3px solid #DFDFDF;
  background: none;
  cursor: pointer;
  color: #DFDFDF;
  font-family: Manrope,serif;
  font-size: 30px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.containerBtn{
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin: 60px 60px;
}
.logo{
  margin: 60px 60px;
}
</style>
