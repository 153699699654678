<template>
  <buttons exit="1" main="1"/>
  <div class="QrScreen">
    <div class="communalText">НОМЕР КВИТАНЦИИ: {{this.code}}</div>
    <div class="communalText">СУММА: {{this.sum}}₽</div>
  </div>
  <img class="kosmoReceipt" src="../../assets/images/kosmo.png" alt="">
  <div class="bntPayReceipt" @click="payReceipt">ОПЛАТИТЬ</div>
</template>

<script>
import Buttons from "@/components/buttons.vue";
import axios from "axios";

export default {
  data() {
    return{
      code:Math.floor(1000000000 + Math.random() * 9000000000),
      sum:(Math.random() * (10000 - 100) + 100).toFixed(2)
    }
  },
  methods:
      {
        async payReceipt(){
          await axios.post(process.env.VUE_APP_API_URL+'/api/user/operation?id='+this.$route.params.id+'&amount='+this.sum+'&additional='+this.code+'&type=communal').then(response=>{
            window.location.href = '/loading/True';
          }).catch((e)=>{
            console.log(e)
            window.location.href = '/loading/False';
          })
        }
      },
  components: {Buttons}
}
</script>

<style>
.QrScreen{
  width: 65%;
  border-radius: 25px;
  border: 3px solid #00FFD4;
  margin: 20px auto;
}

.kosmoReceipt{
  position: absolute;
  top:500px;
  left: 100px
}

.communalText{
  color: #00FFD2;
  font-family: Manrope,serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 50px 50px;
}

.bntPayReceipt{
  width: 65%;
  height: 100px;
  border-radius: 25px;
  background: #00FFD2;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #070707;
  text-align: center;
  font-family: Manrope,serif;
  font-size: 55px;
  font-weight: 500;
  cursor: pointer;
}

.bntPayReceipt:hover{
  background-color: rgba(255, 255, 255);
  color: #000000;
  transition: background 0.5s ease;
}
</style>