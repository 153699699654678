<template>
  <buttons exit="1" main="1"/>
  <div class="grid">
    <div></div>
    <div>
    <div class="containerMobile">
      <div class="TitleMobile">
        ВВЕДИТЕ НОМЕР ТЕЛЕФОНА И СУММУ
      </div>
      <input class="inputs" v-model="phone" placeholder="ВВЕДИТЕ НОМЕР ТЕЛЕФОНА" maxlength="11" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"/>
      <input class="inputs" v-model="sum" placeholder="ВВЕДИТЕ СУММУ" maxlength="5" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" />
    </div>
    <a class="mobilePay" @click="payPhone">ОПЛАТИТЬ</a>
    </div>
    <div>
      <div v-show="flag" class="errorMessage">{{this.errMessage}}</div>
      <img class="cosmoDeposit" src="../../assets/images/kosmo.png" alt="" draggable="false">
    </div>
  </div>
</template>

<script>
import Buttons from "@/components/buttons.vue";
import axios from "axios";

export default {
  data() {
    return{
      phone:"",
      sum:"",
      flag:false,
      errMessage:''
    }
  },
  methods:
      {
        async payPhone() {
          const regex = /^8\d{10}$/;
          console.log(parseInt(this.sum))
          if ((this.phone.length < 11 || !regex.test(this.phone)) && (parseInt(this.sum) === 0 || this.sum==="")){
            this.errMessage = 'НЕКОРРЕКТНЫЕ ТЕЛЕФОН И СУММА'
            this.flag=true
            this.sum=""
            this.phone=""
          }
          else if (this.phone.length < 11 || !regex.test(this.phone)) {
            this.errMessage = 'НЕКОРРЕКТНЫЙ ТЕЛЕФОН'
            this.flag=true
            this.phone=""
          }
          else if (parseInt(this.sum) === 0 || this.sum==="") {
            this.errMessage = 'НЕКОРРЕКТНАЯ СУММА'
            this.flag=true
            this.sum=""
          }
          else
          {
            await axios.post(process.env.VUE_APP_API_URL + '/api/user/operation?id=' + this.$route.params.id + '&amount=' + parseInt(this.sum) +'&additional='+ this.phone +'&type=mobile').then(response => {
              window.location.href = '/loading/True';
            }).catch((e) => {
              console.log(e)
              window.location.href = '/loading/False';
            })
          }
        }
      },
  components: {Buttons}
}
</script>

<style>
.grid{
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
}
.containerMobile{
  border-radius: 25px;
  border: 3px solid #00FFD4;
  margin: 0 auto;
  width: 100%;
  display: block;
}

.TitleMobile{
  color: #00FFD2;
  font-family: Manrope,serif;
  font-size: 70px;
  font-weight: 400;
  display: flex;
  margin: 10px auto 0;
  width: 85%;

}

.inputs{
  width: 85%;
  border-radius: 25px;
  border: 3px solid #DFDFDF;
  display: flex;
  color: #DFDFDF;
  text-align: center;
  font-family: Manrope,serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 50px auto;
  background: none;
}

.mobilePay{
  border-radius: 25px;
  background: #00FFD2;
  margin: 30px auto;
  width: 101%;
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  color: #070707;
  text-align: center;
  font-family: Manrope,serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.mobilePay:hover{
  background-color: rgba(255, 255, 255);
  color: #000000;
  transition: background 0.5s ease;
}

.errorMessage{
  width: 60%;
  height: 80px;
  border-radius: 25px;
  border: 3px solid #00FFD4;
  background: none;
  color: #00FFD4;
  text-align: center;
  font-family: Manrope,serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 100px auto;
  padding: 30px;
}


</style>