<template>
  <img class="logoMain" src="../../assets/images/mainLogo.png" alt="" draggable="false">
  <div class="start">
    <div class="startText">ДЛЯ АВТОРИЗАЦИИ ВСТАВЬТЕ
      ИЛИ ПРИЛОЖИТЕ КАРТУ ...</div>
  </div>
  <a href="/pin">
    <div class="btnTest">
      <div class="btnTestText">ДОПУСТИМ МЫ ПРИЛОЖИЛИ КАРТУ</div>
    </div>
  </a>
</template>

<style>
.logoMain{
  display: flex;
  margin: 50px auto;
}
.start{
  width: 60%;
  height: 286px;
  border-radius: 25px;
  border: 3px solid #DFDFDF;
  display: flex;
  margin: 0 auto;
}
.startText{
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #DFDFDF;
  text-align: center;
  font-family: Manrope, serif;
  font-size: 70px;
}

.btnTest{
  width: 60%;
  height: 120px;
  border-radius: 25px;
  border: 3px solid rgba(223, 223, 223, 0.40);
  background: rgba(217, 217, 217, 0.05);
  display: flex;
  margin: 50px auto;
}

.btnTestText{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(223, 223, 223, 0.40);
  text-align: center;
  font-family: Manrope,serif;
  font-size: 45px;
  margin: 0 auto;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.btnTest:hover{
  background-color: rgba(255, 255, 255, 0.4);
  transition: background 1s ease;
}
</style>