<template>
  <buttons exit="1"/>
  <div class="pinText">ВВЕДИТЕ ПИН-КОД</div>
  <div class="containerPin">
    <input id="pin" class="inputPin" maxlength="4" v-model="password" type="password" value=""  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" >
      <button type="submit" class="btnPin" @click="CheckPassword">ВВОД</button>
  </div>
  <div class="containerMessage">
    <div v-show="flag" class="errorMessage">
      <div class="errorMessageText">
        НЕВЕРНЫЙ ПИН-КОД!!!
      </div>
    </div>
  <img class="cosmoPin" src="../../assets/images/kosmo.png" alt="" draggable="false">
  </div>
</template>

<style>
.pinText{
  display: flex;
  justify-content: center;
  color: #DFDFDF;
  font-family: Manrope, serif;
  font-size: 70px;
  margin: 50px;
}
.containerPin{
  width: 50%;
  display: flex;
  margin: 0 auto;
  height: 110px;
}
.inputPin{
  background: none;
  color: #FFF;
  font-family: Manrope,serif;
  font-size: 80px;
  letter-spacing: 30px;
  width: 70%;
  padding: 10px;
  border: 3px solid #DFDFDF;
  border-radius: 25px;
  text-align:center;
  display: flex;
}

.btnPin{
  width: 260px;
  height: 100%;
  border-radius: 25px;
  background: #00FFD2;
  color: #040404;
  font-family: Manrope,serif;
  font-size: 40px;
  font-weight: 500;
  margin: 0 10px;
  cursor: pointer;
}

.btnPin:hover{
    background-color: rgba(255, 255, 255);
    color: #000000;
    transition: background 0.5s ease;
}

.containerMessage{
  display: flex;
  justify-content: space-between;
  width: 45%;
  margin: 60px auto 0;
}

.cosmoPin{
  display: flex;
  margin: 0 auto;
}

.errorMessage{
  width: 70%;
  height: 120px;
  border-radius: 25px;
  border: 3px solid #00FFD4;
  color: #00FFD4;
  text-align: center;
  font-family: Manrope,serif;
  flex-direction: column;
  justify-content: center;
  display: flex;
  font-size: 40px;
  margin: 60px auto 0;
}

</style>
<script>
import Buttons from "@/components/buttons.vue";
import axios from "axios";

export default {
  data() {
    return{
      password:"",
      flag:""
    }
  },
  methods:
      {
        async CheckPassword() {
          if (this.password.length !== 4){
            this.password = ""
            this.flag = "True"
          }
          else{
            for (let i of this.password) {
              if (isNaN(parseInt(i))) {
                this.flag = "True"
                this.password = ""
                break
              }
            }
          }
          if (this.password !== ""){
            await axios.get(process.env.VUE_APP_API_URL+'/api/user/get_id?code='+this.password).then(response=>{
               window.location.href='/main/'+response.data.id
            }).catch((e)=>{
              console.log(e)
              this.flag = "True"
              this.password = ""
            })
          }
        }
      },
  components: {Buttons}
}
</script>